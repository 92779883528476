//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    CContent: () => import("@/components/CContent"),
    Tab: () => import("@/components/TabUtil"),
    ActionRulew: () => import("./action-rulew"),
    CPages: () => import("@/components/CPages"),
  },
  props: {
    //规则版本信息
    selRow: {
      type: Object,
      default: null,
    },
    teamValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    "#main";
    return {
      loading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },

      // CX Title组件
      titleInfo: {
        // 控制左侧：按钮组四个
        btnShow: [
          {
            type: "addBtn",
            ishow: true,
          },
          {
            type: "startBtn",
            ishow: false,
            disabled: true,
          },
          {
            type: "closeBtn",
            ishow: false,
            disabled: true,
          },
          {
            type: "delBtn",
            ishow: true,
            disabled: true,
          },
        ],
      },
      tabOptions: ["部门", "成员组"],
      operationName: 1,
      tableData: [],
      formParams: {
        t: "部门",
        g: [],
        pn: [
          {
            name: "",
          },
        ],
        word: [
          {
            name: "",
          },
        ],
        mk1: "工作",
        mk2: "未知",
        id: "",
      },
      processOptions: [],
      resultData: {
        CSRules: [],
        MKRules: [],
      },
      activeItem: "部门",
      cellStyle: {
        textAlign: "center",
      },
      rule: {},
      id: "",
    };
  },
  watch: {},
  filters: {},
  mounted() {
    this.getDataList();
  },
  methods: {
    /**
     * 分页
     */
    pageChange(val) {
      this.pageData = val;
      this.getDataList();
    },
    getTab(item) {
      this.activeItem = item.join();
      this.getDataList();
    },

    // 删除某一行
    handleDelt(row) {
      this.$confirm("此操作将删除此行为规则, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/BehaviorAnalyse/DelBehaviorAnalyse.ashx", {
              Ids: [row.Id],
              teamId: this.teamValue,
            })
            .then((res) => {
              if (res.res == 0) {
                this.$message({
                  showClose: true,
                  message: "删除成功！",
                  type: "success",
                });
                this.getDataList();
              }
            });
        })
        .catch(() => {});
    },
    // 新增
    addClick() {
      this.operationName = 1;
      this.id = "";
      Object.assign(this.$data.formParams, this.$options.data().formParams);
      this.formParams.t = this.activeItem;
      this.$modal.show("actionRuleXmodal");
    },
    // 编辑
    handleEdit(row) {
      this.id = row.Id;
      // this.$refs.detail.id=row.Id
      this.operationName = 2;
      if (this.id) {
        this.$modal.show("actionRuleXmodal");
      }
    },
    // 获取表单数据
    getDataList() {
      this.loading = true;
      const data = {
        type: this.activeItem == "部门" ? 1 : 2,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        teamId: this.teamValue,
        searchtext: null,
        configId: this.selRow.Id,
      };
      this.$http
        .post("/BehaviorAnalyse/GetBehaviorAnalyse.ashx", data)
        .then((result) => {
          if (result.res == 0) {
            this.tableData = result.data.Data;
            this.pageData.totalNum = result.data.TotalCount;
            this.loading = false;
          }
        });
    },
  },
};
